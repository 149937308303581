export default {
	minimoss: [
		"train.gif",
		"arrangement1.gif",
		"arrangement2.gif",
		"happynewyear.gif",
		"moonthief.gif",
		"smallworld.gif",
		"train.gif",
		"window.gif"
	],
  mark_ferrari: [
    "bridge.gif",
    "bridge_raining.gif",
    "coast.gif",
    "falls.gif",
    "forrest.gif",
    "lake.gif",
    "nature.gif",
    "northlights.gif",
    "sea.gif"
  ],
  valenberg: [
    "streets.gif",
    "shop.gif",
    "highlands.gif",
    "virtuaverse.gif",
    "sushi.gif",
    "girlinrain.gif",
    //"exodus.gif",
    "drift.gif",
    //"daftpunk.gif",
    //"blade.gif",
    "highfloor.gif",
    "lowlands.gif",
    "moon.png"
  ],
  kirokaze: [
		"holo.gif",
		"ontop.gif",
		"upload.gif",
		"upload2.gif",
		"transform.gif",
    "spaceport.gif",
    "bad_landing.gif",
    "bluebalcony.gif",
    "coffeeinrain.gif",
    "dark_pillar.gif",
    "familydinner.gif",
    "cemetry.gif",
    "sandcastle.gif",
    "horse.gif",
    "nightlytraining.gif",
    "attack.gif",
    "zombies.gif",
    "citymirror.gif",
    "droidcrime.gif",
    "elderorc.gif",
    "factory5.gif",
    "iplayoldgames.gif",
    "metro_final.gif",
    "pilot.gif",
    "player2.gif",
    "robot_alley.gif",
    "shootingstars.gif",
    "thieves.gif",
    "train_city.gif",
    "troll_cave.gif",
    "youngatnight.gif"
  ]
};

