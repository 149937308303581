import { useState, useRef, useEffect } from "react";
import { useTweaks, makeButton } from "use-tweaks";

import "./App.css";
import {load, loadOr, save, toBool, randomSplash, time, useWindowSize} from "./Utility";

const ReactGA = require("react-ga");

ReactGA.initialize("UA-102501725-3");
ReactGA.set({ page: window.location.pathname + window.location.search });
ReactGA.pageview(window.location.pathname + window.location.search);
const fire = (eventName,f) => {
	ReactGA.event({
		category: 'behavior',
		action: eventName
	});
	console.log("fired",eventName);
	f();
}

// --- components start here
const Greeting = () => {
	const greetings = () => {
		const date = new Date();
		let hours = date.getHours();
		let ampm = hours >= 12 ? "pm" : "am"
		console.log(hours)
		if(ampm === "pm") {
			if(hours >= 23) { 
				return([
					"Night-owl mode activated",
					"Sleep is for the weak",
					"Good night",
					"Have a good rest",
					"Who said nights are for sleep",
					"Maybe go to bed early today"])
			} else if(hours >= 20) {
				return([
					"Time to unwind",
					"Time to ponder on the day",
					"See you space cowboy",
					"Good night"
				])
			} else if(hours >= 18) {
				return([
					"Pack up and head home",
					"Have a safe way home",
					"Enjoy your dinner",
					"Winner winner chicken dinner",
					"Time to prep for dinner",
					"Good job today",
					"Try again tomorrow"
				]);
			} else if(hours >= 15) {
				return([
					"Time to slack a bit",
					"The job is almost done",
					"Nothing to do? Join discord.",
					"Work hard play hard",
					"Cubism!",
					"Here's some pixel art for you",
					"No 8bitdash no life",
				 	"A circle-free environment!",
					"Best rest is earned"
				])
			} else if(hours >= 12) {
				return([
					"Enjoy your lunch time",
					"Lunch prep time",
					"Good job this morning",
					"0% sugar",
					"Try the stretch mode",
					"Wash your hands",
					"Don't be a dick",
					"Try harder"
					]
				);
			}
		}
		// AM
		 else {
			if(hours >= 9) {
				return([
					"What a wonderful morning",
					"This is gonna be your day",
					"Greetings and salutations",
					"New day new dream",
					"Good morning community",
					"Good morning"
				])
			} else if(hours >= 6) {
				return([
					"The early bird catches the worm",
					"Rise and shine",
					"Another day another story",
					"Take it easy today",
					"You are early"
				])
			} else if(hours >= 3) {
				return([
					"Time to go to bed",
					"Time to party real hard",
					"Dead-tired pixels",
					"Beware of ghosts",
					"Conscious in a dream",
					"Dreaming or awake",
					"Person behind you",
					"Awake or dreaming",
					"Stay a little more",
					"Witching hour",
					"Devil's hour",
					"Haunted hour",
					"REM active",
					"The day has 24 hours",
					"Taking on the night shift",
					"See you tonight space cowboy"])
			} else if(hours >= 0) {
				return([
					"Sleep is still for the weak",
					"Your night shift just started",
					"Today is young",
					"Try the smooth mode",
					"See you tonight space cowboy",
					"Propose new greetings in Discord"
				])
			}  
		}
	}
	const greeting = () => {
		const items = greetings();
		const item = items[Math.floor(Math.random()*items.length)];
		return(item)
	}

	const [greetingState, setGreetingState] = useState(greeting());
  const style = {
    fontWeight: "800",
    fontSize:  "0.24em", // golden ratio
  };
	const updateGreeting = () => {
		setGreetingState(greeting());
		setTimeout(updateGreeting, 1000*60*5);
	};
	useEffect(() => updateGreeting(), [])
	return(<div id="greeting" style={style}>{greetingState}</div>)
}

const Clock = () => {
  const ref = useRef(null);
  const tweaks = useTweaks("clock", {
    size: { value: parseFloat(loadOr("clock", "size", "5")), min: 1, max: 10 },
    shadow: toBool(loadOr("clock","shadow","true")),
    title: toBool(loadOr("clock","title", "true")),
    opacity: { value: parseFloat(loadOr("clock","opacity","1")), min: 0, max: 1 },
    color: loadOr("clock", "color", "#fff"),
		greeting: toBool(loadOr("clock","greeting", "true"))
  });
  const [showBanner, setShowBanner] = useState(tweaks.title);
	const [timeState, setTimeState] = useState(time());

	const updateClock = () => {
		setTimeState(time());
		setTimeout(updateClock, 1000);
	}

  const style = {
    fontWeight: "800",
    color: tweaks.color,
    opacity: tweaks.opacity,
    fontSize: tweaks.size + "em",
    textShadow: tweaks.shadow ? "0px 0px 2px #000" : "0px 0px 0px #000",
  };
  useEffect(() => {
    setTimeout(() => {
      setShowBanner(false);
    }, 1000);
		setTimeout(updateClock, 1000);
  });
	useEffect(() => {
		Object.keys(tweaks).forEach(k => {
			save("clock", k, tweaks[k])
		})
	}, [tweaks])
  return (
    <div id="centering">
      <div id="clock" ref={ref} style={style}>
        {showBanner ? "8bitdash" : timeState}
				{tweaks.greeting ? <Greeting/> : null}
      </div>
    </div>
  );
};



// --- bar component

const Bar = ({ splash, fontName }) => {
  const barStyle = {
    position: "absolute",
    textAlign: "center",
    fontWeight: "400",
		opacity: 0.5,
    fontSize: "1em",
    bottom: "0px",
    height: "2.0em",
    width: "100%",
    display: "flex",
    flexDirection: "columns",
    justifyContent: "space-between",
  };
  return (
    <div style={barStyle}>
      <div className="padded-left">
        {splash ? splash.folder : ""} / {fontName}
      </div>
      <div className="padded-right">
				<ReactGA.OutboundLink
					eventLabel="discord"
					to="https://discord.gg/WStut4A4ma"
				>Discord</ReactGA.OutboundLink> 
      </div>
    </div>
  );
};

const TopBar = ({ showSettings }) => {
  const barStyle = {
    position: "absolute",
    textAlign: "center",
    fontWeight: "400",
    fontSize: "1.0em",
    top: "0.5em",
		opacity: 0.5,
    zIndex: "9",
    height: "1.0vw",
    width: "100%",
    display: "flex",
    flexDirection: "columns",
    justifyContent: "space-between",
  };
  return (
    <div style={barStyle}>
      <div></div>
      <div className="padded-right">
        <a onClick={() => fire("show-settings",showSettings)} href="#">
          Tweaks
        </a>
      </div>
    </div>
  );
};

// --- app root component

function App() {
  const ref = useRef(null);
  const size = useWindowSize();
  const tweaks = useTweaks("app", {
    clock: toBool(loadOr("app", "clock","true")),
    smooth: toBool(loadOr("app", "smooth", "false")),
		stretch: toBool(loadOr("app", "stretch", "false")),
		grayscale: toBool(loadOr("app", "grayscale", "false")),
		sepia: toBool(loadOr("app", "sepia", "false")),
    font_i: { value: parseFloat(loadOr("app", "font_i", "0")), min: 0, max: 4 },
    ...makeButton("Change splash", () => setSplash(randomSplash())),
    ...makeButton("Hide tweaks", () => setShowTweaks(false)),
  });

  const [splash, setSplash] = useState(null);
  const [font, setFont] = useState("minecraftfont");
  const [showTweaks, setShowTweaks] = useState(toBool(loadOr("app","tweaks","true")));

  const fonts = ["minecraftfont", "print21", "Inter"];

  const backdropUrl = splash
    ? "splash/" + splash.folder + "/" + splash.filename
    : "";

	const filters = () => {
		var applied = []
		if(tweaks.grayscale) { 
			applied.push("grayscale(1.0)");
		}
		if(tweaks.sepia) {
			applied.push("sepia(1.0)");
		}
		if(applied.length > 0) {
			return(applied.join(" "))
		} else {
			return("none");
		}
	}

  const appStyle = {
    fontFamily: font,
    color: "white",
    height: size.height + "px",
    width: size.width + "px",
		filter: filters(),
    background: "url(" + backdropUrl + ") no-repeat",
		backgroundSize: tweaks.stretch ? "100% 100%" : "cover",
    imageRendering: tweaks.smooth ? "auto" : "crisp-edges",
  };

	console.log(filters())

  useEffect(() => {
    setSplash(randomSplash());
  }, []);
  useEffect(() => {
    ref.current.style.backgroundSize = 
			tweaks.stretch ? "100% 100%" : "cover"
  }, [appStyle]);
  useEffect(() => {
    setFont(fonts[Math.floor(tweaks.font_i)]);
  }, [tweaks.font_i]);
  useEffect(() => {
    const el = document.getElementsByClassName("tp-dfwv")[0];
    el.hidden = !showTweaks;
		save("app","tweaks", showTweaks)
  }, [showTweaks]);
	useEffect(() => {
		Object.keys(tweaks).forEach(k => {
			save("app", k, tweaks[k])
		})
	}, [tweaks])

  // if tweaks is hidden, then display the top bar
  // have a newsletter that people can sign up whenever theres new art available
  return (
    <div ref={ref} style={appStyle} className="App">
      {!showTweaks ? <TopBar showSettings={() => setShowTweaks(true)} /> : null}
      {tweaks.clock ? <Clock /> : null}
      <Bar splash={splash} fontName={font} />
    </div>
  );
}

export default App;
